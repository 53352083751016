<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<style>
.card-list{
  min-height: 480px;
}
.pagination-list{
  position: absolute;
  left: 15px;
  bottom: 15px;
}

.p-button-success{
  background-color: #3699FF !important;
}

.p-button-info{
 background-color: #6993FF !important;
}

.p-button-primary{
  background-color: #1BC5BD !important;
}
</style>

<script>
export default {
  components: {}
};
</script>